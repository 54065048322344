import React from 'react'
import axios from 'axios'
import { navigate } from 'gatsby'
import * as storage from '../utils/storage'
import { addDays } from '../utils/date'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      deleting: false,
      first_name: '',
      last_name: '',
      mobile: '',
      email: '',
      photo: '',
      photo_url: 'https://bulma.io/images/placeholders/128x128.png',
      public_contacts: '',
    }
  }

  componentDidMount() {
    let user = storage.getItem('user')
    if (user) {
      user = JSON.parse(user)
      const {
        first_name,
        last_name,
        mobile,
        email,
        location,
        photo_url,
        public_contacts,
      } = user
      this.setState({
        first_name,
        last_name,
        mobile,
        email,
        location,
        public_contacts,
      })
      if (photo_url) this.setState({ photo_url })
    } else {
      axios
        .post(
          `${process.env.API_BASE_URL}/graphql`,
          {
            query: `query {
            me {
                id
                first_name
                last_name
                email
                location
                mobile
                photo_url
                public_contacts
            }
          }`,
          },
          {
            headers: {
              Authorization: 'Bearer ' + storage.getItem('access_token'),
            },
          }
        )
        .then(res => {
          const user = res.data.data.me
          storage.setItem('user', JSON.stringify(user))
          storage.setItem('expired_at', addDays(30))
          const {
            first_name,
            last_name,
            mobile,
            email,
            location,
            photo_url,
            public_contacts,
          } = user
          this.setState({
            first_name,
            last_name,
            mobile,
            email,
            public_contacts,
            location,
          })
          if (photo_url) this.setState({ photo_url })
        })
        .catch(err => console.log(err))
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleDelete(e) {
    e.preventDefault();
    const confirm = window.confirm("Are you sure you want to delete your profile? This cannot be undone!");
    if (confirm) {
      this.setState({ deleting: true })
      axios
        .post(
          `${process.env.API_BASE_URL}/api/me/delete`,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + storage.getItem('access_token'),
            },
          }
        )
        .then(res => {
          this.setState({ deleting: false })
          alert('Your profile has now been deleted.')
          storage.clear()
          navigate('/')
        })
        .catch(err => this.setState({ deleting: false }))
    }
  }

  handleFileChange(event) {
    var reader = new FileReader()
    reader.onload = e => {
      this.setState({ photo_url: e.target.result })
    }
    reader.readAsDataURL(event.target.files[0])
  }

  handleSubmit(event) {
    if (
      !this.state.mobile.match(
        /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/
      )
    ) {
      alert('Mobile is not a valid Australian number')
      event.preventDefault()
      return
    } else {
      event.preventDefault()
      this.setState({ loading: true })
      // @TODO: probably move to a utility class
      let formData = new FormData(event.target)
      axios
        .post(`${process.env.API_BASE_URL}/api/me`, formData, {
          headers: {
            Authorization: 'Bearer ' + storage.getItem('access_token'),
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          this.setState({ loading: false })
          const user = res.data.data
          storage.setItem('user', JSON.stringify(user))
          storage.setItem('expired_at', addDays(30))
          const { photo_url } = user
          if (photo_url) this.setState({ photo_url })
          alert('Profile Updated!')
          window.location.reload()
        })
        .catch(err => {
          if (err.response) {
            const errors = err.response.data.errors
            for (let key in errors) {
              alert(errors[key][0])
              break
            }
          }
          this.setState({ loading: false })
        })
    }
  }

  render() {
    console.log(this.state)
    return (
      <form
        className="my-profile"
        method="post"
        onSubmit={e => this.handleSubmit(e)}
        encType="multipart/form-data"
      >
        <h2 className="title is-2 fd-center">My Profile</h2>
        <div className="fd-center">
          <div className="image is-128x128" style={{ margin: '0 auto' }}>
            <img
              className="is-rounded"
              src={this.state.photo_url}
              alt="profile"
              style={{ height: '128px' }}
            />
          </div>
          <br />
          <div className="file" style={{ justifyContent: 'center' }}>
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                name="photo"
                onChange={e => this.handleFileChange(e)}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <FontAwesomeIcon icon={faUpload} />
                </span>
                <span className="file-label">Change photo</span>
              </span>
            </label>
          </div>
          <br />
        </div>
        {/* name */}
        <div className="field">
          <label className="label">First Name</label>
          <div className="control">
            <input
              className="input"
              name="first_name"
              type="text"
              value={this.state.first_name}
              onChange={e => this.handleChange(e)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Last Name</label>
          <div className="control">
            <input
              className="input"
              name="last_name"
              type="text"
              value={this.state.last_name}
              onChange={e => this.handleChange(e)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Mobile</label>
          <div className="control">
            <input
              className="input"
              name="mobile"
              type="text"
              value={this.state.mobile}
              onChange={e => this.handleChange(e)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Email</label>
          <div className="control">
            <input
              className="input"
              name="email"
              type="text"
              value={this.state.email}
              onChange={e => this.handleChange(e)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">City</label>
          <div className="control">
            <div className="select">
              <select
                name="location"
                value={this.state.location}
                onChange={e => this.handleChange(e)}
              >
                <option>Please select one</option>
                <option value="sydney">Sydney</option>
                <option value="melbourne">Melbourne</option>
                <option value="brisbane">Brisbane</option>
                <option value="perth">Perth</option>
              </select>
            </div>
          </div>
        </div>
        <div className="field">
          <label>Contact Details to Share with My Matches</label>
          <div className="control">
            <div className="select">
              <select
                name="public_contacts"
                value={this.state.public_contacts}
                onChange={e => this.handleChange(e)}
              >
                <option value="mobile,email">Show mobile and email</option>
                <option value="mobile">Show mobile only</option>
                <option value="email">Show email only</option>
              </select>
            </div>
          </div>
        </div>
        <div className="fd-center">
          <button
            type="submit"
            className={`button is-medium has-background-link has-text-white is-hidden-mobile ${
              this.state.loading ? 'is-loading' : ''
            }`}
          >
            Save Changes
          </button>
          <div className="fd-central">
            <button
              type="submit"
              className={`button is-medium has-background-link has-text-white is-hidden-tablet is-fullwidth ${
                this.state.loading ? 'is-loading' : ''
              }`}
            >
              Save Changes
            </button>
          </div>
        </div>
        <hr />
        <div className="fd-center">
          <p className="fd-center" style={{ maxWidth: '500px' }}>
            By selecting the Delete Account button below all of your profile and
            matching data will be erased.
          </p>
          <br />
          <button
            type="button"
            onClick={e => this.handleDelete(e)}
            className={`button is-medium grey is-hidden-mobile ${
              this.state.deleting ? 'is-loading' : ''
            }`}
          >
            Delete Account
          </button>
          <div className="fd-central">
            <button
              type="button"
              onClick={e => this.handleDelete(e)}
              className={`button is-medium grey is-hidden-tablet is-fullwidth ${
                this.state.deleting ? 'is-loading' : ''
              }`}
            >
              Delete Account
            </button>
          </div>
        </div>
      </form>
    )
  }
}

export default Profile
