import React from 'react'
import Layout from '../components/layout'
import Profile from '../components/profile'

const ProfilePage = () => (
  <Layout>
    <Profile></Profile>
  </Layout>
)

export default ProfilePage